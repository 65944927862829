import "./App.css";

import { EthereumClient, modalConnectors, walletConnectProvider } from "@web3modal/ethereum";
import { WagmiConfig, configureChains, createClient } from "wagmi";
import { goerli, mainnet } from "wagmi/chains";

import Minter from "./Minter";
import Success from "./Success";
import Technical from "./Technical";
import { Web3Modal } from "@web3modal/react";
import { infuraProvider } from "wagmi/providers/infura";

function App() {
    // window.location.href = 'https://akidcalledbeast.com';
    window.alert = (a) => {
        console.log(a);
    };
	const chains = [mainnet];

	// Wagmi client
	const { provider } = configureChains(chains, [walletConnectProvider({ projectId: "a1d481150282d5a19fef8d22df7a953f" })]);
	// const { provider } = configureChains(chains, [infuraProvider({ apiKey: "7f94af80db2f464786eb11a02afa3287" })]);
	const wagmiClient = createClient({
		autoConnect: true,
		connectors: modalConnectors({ appName: "akcb", chains }),
		provider,
	});

	// Web3Modal Ethereum Client
	const ethereumClient = new EthereumClient(wagmiClient, chains);

	const urlSearchParams = new URLSearchParams(window.location.search);

	let thePage = <Minter />;
	// thePage = <Technical />;

	if (urlSearchParams.get("success")) {
		thePage = <Success />;
	}

    // if (window.location.host === 'mint-2.akidcalledbeast.com' || window.location.host === 'localhost:3000') {
    //     thePage = <Minter />;
    // }

    if (!urlSearchParams.get('secret-load-page')) {
        thePage = <Minter />;
        window.location.href = 'https://akidcalledbeast.com';
    }

	return (
		<div className="App">
			<WagmiConfig client={wagmiClient}>{thePage}</WagmiConfig>
			<Web3Modal projectId="a1d481150282d5a19fef8d22df7a953f" ethereumClient={ethereumClient} />
		</div>
	);
}

export default App;
