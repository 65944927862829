import { AKCB_CONTRACT_ADDRESS, WEB3_RPC_URL, USE_INJECTED_PROVIDER } from "./constants";

require("dotenv").config();

const Web3 = require("web3");

const web3 = new Web3(window.ethereum && USE_INJECTED_PROVIDER ? window.ethereum : WEB3_RPC_URL);

const contractABI = require("../contract-abi.json");
const contractAddress = AKCB_CONTRACT_ADDRESS;

export const getBasicInformations = async () => {
	const allInformations = await new web3.eth.Contract(contractABI, contractAddress).methods.getInformations().call();

	const currentRoundName = allInformations[0];
	const currentSupplyMales = allInformations[1];
	const currentSupplyFemales = allInformations[2];
	const currentTime = allInformations[3];
	const currentCost = allInformations[4];
	const maxAddressAmount = allInformations[5];
	const currentTotalSupply = allInformations[6];
	const hasSaleStarted = allInformations[7];
	const onlyBeastListed = allInformations[8];

	return {
		currentRoundName: currentRoundName,
		currentCost: web3.utils.fromWei(currentCost),
		currentSupplyMales: currentSupplyMales,
		currentSupplyFemales: currentSupplyFemales,
		currentTime: currentTime,
		maxAddressAmount: maxAddressAmount,
		currentTotalSupply: currentTotalSupply,
		hasSaleStarted: hasSaleStarted,
		onlyBeastListed: onlyBeastListed,
	};
	//alert(myArray[0]);
};
