import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";

import { Slide, ToastContainer } from "react-toastify";

import React from "react";

const Success = (props) => {
	return (
		<div>
			<div className="header">
				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="container justify-content-between justify-content-center">
						<a className="navbar-brand" href="index.html">
							<img className="lazy" data-src="./assets/img/svg/logo.svg" alt="" />
						</a>
					</div>
				</nav>
			</div>
			<div className="main-content">
				<div>
					<div className="container">
						<div className="row">
							<div className="col-md-5 order-2 order-md-1">
								<div className="d-flex align-items-center h-100 mt-4 mt-md-0">
									<div className="content">
										<h1 className="main-title text-uppercase">
											Welcome
											<br />
											to the
											<br />
											beasthood
										</h1>
										<p className="mt-4">
											CONGRATULATIONS, YOU HAVE SECURED YOUR KID CALLED BEAST. ON LAUNCH, YOUR BEAST CONTAINER WILL TRANSFORM INTO IT’S FINAL FORM AND YOU WILL HAVE ACCESS TO ALL
											OF THE BEASTS FUNCTIONS AND ARTWORK. YOU CAN THEN VERIFY AND JOIN THE BEASTHOOD DISCORD CHANNEL.
										</p>
										<div className="d-flex flex-column flex-lg-row my-5  align-items-center align-items-md-start">
											<a href="https://studio.akidcalledbeast.com" className="btn btn-outline-dark rounded-pill text-uppercase w-100 w-lg-50 py-3 ms-lg-3 btn-success-links">
												View in Studio
											</a>
											<a href="http://discord.gg/akidcalledbeast" className="btn btn-outline-dark rounded-pill text-uppercase w-100 w-lg-50 py-3 ms-lg-3 btn-success-links">
												Join discord
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-7 order-1 order-md-2">
								<div className="ps-md-5 ms-md-5">
									<div className="img-wrapper">
										<video
											autoPlay={false}
											loop={false}
											muted={false}
											playsInline={false}
											controls={true}
											className="mint-img lazy img-fluid"
											style={{ height: "100%", borderRadius: "17px" }}
										>
											<source src="./assets/video/mint-success-video.mp4" type="video/mp4" />
										</video>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer position="bottom-right" autoClose={5000} transition={Slide} hideProgressBar />
		</div>
	);
};

export default Success;
