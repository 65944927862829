import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";

import { AKCB_CONTRACT_ADDRESS, REFRESH_RATE } from "./utils/constants";
import { Slide, ToastContainer, toast } from "react-toastify";
import {
	useAccount,
	useContractWrite,
	useDisconnect,
	usePrepareContractWrite,
	useWaitForTransaction
} from "wagmi";
import { useEffect, useState } from "react";

import Countdown from "react-countdown";
import React from "react";
import axios from "axios";
import { generate_proof } from "./proofs";
import { getBasicInformations } from "./utils/interact";
import keccak256 from "keccak256";
import { useWeb3Modal } from "@web3modal/react";

const Web3 = require("web3");
const web3 = new Web3();



let countdownapi = null;
let theMintingStatus = true;

let archeTypes = [];
let ingredients = [];
let firstRun = true;
let onlyBeastListed = false;
let userBeastListed =true;
let currentSupplyMale = [];
let currentSupplyFemale = [];
let alreadyMinted = false;

archeTypes.push(
	{ id: 1, order: 356, parent: "Bullhattan District - Business" },
	{ id: 2, order: 235, parent: "Ramone Alley - Punks" },
	{ id: 3, order: 236, parent: "Wagmi Way - Degens" },
	{ id: 4, order: 456, parent: "Champions Court - Athletes" },
	{ id: 5, order: 136, parent: "Beat Street - Musician" },
	{ id: 6, order: 245, parent: "L33T Labs - Nerds" },
	{ id: 7, order: 135, parent: "Fort Flex - Hustler" },
	{ id: 8, order: 246, parent: "Trusty Lake Estates - Prep" },
	{ id: 9, order: 123, parent: "Faded Heights - Baddie" },
	{ id: 10, order: 124, parent: "MoBA (Museum of Beast Arts) - Artist" },
	{ id: 11, order: 234, parent: "The Odeon - Drama" },
	{ id: 12, order: 134, parent: "Concrete Jungle - Rebels" },
	{ id: 13, order: 145, parent: "Nowhere Parks - Loner" },
	{ id: 14, order: 346, parent: "Cloudy Gardens - Day Dreamer" },
	{ id: 15, order: 345, parent: "Cortado Cafe - Hipster" },
	{ id: 16, order: 125, parent: "Gaia Grove - Hippie" },
	{ id: 17, order: 126, parent: "Studio 66 - Popular" },
	{ id: 18, order: 146, parent: "Silicon Alley - Hacker" },
	{ id: 19, order: 156, parent: "Moo Moo Meadows - Wild" },
	{ id: 20, order: 256, parent: "Michelin Markets - Foodies" }
);

const Minter = (props) => {
	//State variables
	const [currentSupply, setCurrentSupply] = useState([]);
	const [mintAmount, setMintAmount] = useState(1);
	const [mintingText, setMintingText] = useState("Fetching");
	const [currentCost, setMintingCost] = useState("-");
	const [maxMintAmount, setMaxMintAmount] = useState("1");
	const [currentTime, setCurrentTime] = useState(1639429200000);
	const [currentRoundName, setCurrentRound] = useState("-");
	const [currentTotalSupply, setCurrentTotalSupply] = useState(false);
	const [currentSupplyIndex, setCurrentSupplyIndex] = useState(0);
	const [currentSupplyName, setCurrentSupplyName] = useState("SELECT 3 OPTIONS");
	const [selectedGender, setGender] = useState(2);
	const { isOpen, open, close } = useWeb3Modal();
	const [configInfo, setConfigInfo] = useState({
		mintType:1, mintGender:1, value:"0", proof:[]
	})
	const [preprarEnabled, setPrepareEnabled] = useState(false);

	const { address, isConnected } = useAccount()
	const { disconnect } = useDisconnect()

	/*
HONEST     = 1
SENSITIVE  = 2
LOUD       = 3
STOIC	   = 4
AWARE 	   = 5
AMBITIOUS  = 6
*/



const refreshInformations = async () => {
	try {
		if (firstRun && isConnected) {
			firstRun = false;
			var foundIt = await checkBeastlist();
			if (!foundIt.status) {
				accessDeniedModal("show");
				userBeastListed = false;
				return;
			} else {
				accessDeniedModal("hide");
				userBeastListed = true;
			}
		}
		if((onlyBeastListed && !userBeastListed) || !isConnected || alreadyMinted)
		{
			return;
		}
		const theResponse = await getBasicInformations();

		setCurrentTime(new Date(Number(theResponse.currentTime)));
		setMintingCost(theResponse.currentCost);
		setMaxMintAmount(theResponse.maxMintAmount);

		setCurrentRound(theResponse.currentRoundName);
		setCurrentTotalSupply(theResponse.currentTotalSupply);
		theMintingStatus = theResponse.hasSaleStarted;
		onlyBeastListed = theResponse.onlyBeastListed;

		setCurrentSupply(theResponse.currentSupplyMales);
		currentSupplyMale = theResponse.currentSupplyMales;
		currentSupplyFemale = theResponse.currentSupplyFemales;
		setPrepareEnabled(true);

		countdownapi.start();
        setTimeout(refreshInformations, REFRESH_RATE);
        return;
	} catch (e) {
		console.log(e);
	}
	setTimeout(refreshInformations, 2000);
};

 useAccount({
    onConnect({ address, connector, isReconnected }) {
	  userBeastListed = true;
	  firstRun = true;
    },
  })


 useAccount({
    onDisconnect() {
     	accessDeniedModal("hide");
		open();
    },
  })




const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
	address: AKCB_CONTRACT_ADDRESS,
	enabled: preprarEnabled,
	abi: [
		{
			inputs: [
				{
					internalType: "uint256",
					name: "_typeB",
					type: "uint256",
				},
				{
					internalType: "uint256",
					name: "_typeG",
					type: "uint256",
				},
				{
					internalType: "bytes32[]",
					name: "proof",
					type: "bytes32[]",
				},
			],
			name: "mintNFT",
			outputs: [],
			stateMutability: "payable",
			type: "function",
		},
	],
	functionName: "mintNFT",
	args: [configInfo.mintType, configInfo.mintGender, configInfo.proof],
	overrides: {
		value: configInfo.value,
	  }
});

const { data, error:writeError, isError, write }  = useContractWrite(config)

useEffect(() => {
	try{
		if(!isOpen)
		{
			if(!isConnected)
				showConnectModal("show");
		}
	}
	catch{}
}, [isOpen])

useEffect(() => {
	try{
		if(isError || isPrepareError)
		{
			let message = (prepareError || writeError)?.message;
			message = message.toString().toLowerCase();

			if (message.indexOf("insufficient funds for gas") > -1) {
				toast.error("Insufficient Funds!");
			} else if (message.indexOf("user is not beast listed") > -1) {
				console.log("You're not beast listed!");
				// accessDeniedModal("show");
			} else if (message.indexOf("enough supply") > -1) {
				toast.error("Not enough supply available in this type");
			} else if (message.indexOf("max nft per address exceeded") > -1) {
				alreadyMinted = true;
				toast.error("You have already minted the maximum allowed NFTs.");
			}
			else if (message.indexOf("underlying network") > -1) {
				toast.error("Please switch to correct network");
			}
			else if (message.indexOf("user rejected") > -1) {
				toast.error(message);
			}

			else if (message.indexOf("cannot estimate") > -1) {
					//do nothing
			}
			else
				alert(message);

		}
	}
	catch{}


  }, [isError, isPrepareError])


  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  useEffect(() => {
	try{
		if(isLoading)
		{
			showMintingModal("show");
		}
		else{
			showMintingModal("hide");
		}
		if(isSuccess)
		{
			window.$(window).attr("location", "/?success=1");
		}
	}
	catch{}


  }, [isLoading, isSuccess])

  useEffect(() => {
	try{
		firstRun = true;
		userBeastListed = true;
		alreadyMinted = false;
		refreshInformations();
	}
	catch{}


  }, [address])


	function uncheck() {
		window.$(".card-list.attributes li .card-checkbox").filter(":checkbox").prop("checked", false);
		window.$(".card-list.attributes li .card-checkbox").filter(":checkbox").prop("disabled", false);
	}

	function randomize() {
		setCurrentSupplyIndex(0);
		setCurrentSupplyName("RANDOMIZING....");
		ingredients = [];

		for (let x = 200; x <= 2600; x = x + 200) {
			let rnd = Math.floor(Math.random() * 6 + 1);
			setTimeout(function () {
				uncheck();
				window.$(`.card-list.attributes li:nth-child(${rnd}) .card-checkbox`).filter(":checkbox").prop("checked", true);
			}, x);
		}

		var x = Math.floor(Math.random() * 6 + 1);
		var y = Math.floor(Math.random() * 6 + 1);
		var z = Math.floor(Math.random() * 6 + 1);

		var arr = [];
		while (arr.length < 3) {
			var r = Math.floor(Math.random() * 6) + 1;
			if (arr.indexOf(r) === -1) arr.push(r);
		}
		setTimeout(function () {
			uncheck();
			window
				.$(".card-list.attributes li:nth-child(" + arr[0] + ") .card-checkbox")
				.filter(":checkbox")
				.prop("checked", true);
			window
				.$(".card-list.attributes li:nth-child(" + arr[1] + ") .card-checkbox")
				.filter(":checkbox")
				.prop("checked", true);
			window
				.$(".card-list.attributes li:nth-child(" + arr[2] + ") .card-checkbox")
				.filter(":checkbox")
				.prop("checked", true);

			ingredients = arr;
			setOrder();

			var check = window.$(window.$(".card-list").find("input[type='checkbox']:checked")).length;
			if (check > 2) {
				window.$(".card-list.attributes").find("input[type='checkbox']").not(":checked").attr("disabled", true);
			}
		}, 2800);
	}

	async function checkBeastlist(withserver) {
		// if (!withserver) {
		// withserver = true;
		// }
		if (!isConnected) {
			toast.error("Wallet not connected", { toastId: "wallet-not-connected" });
			return {
				status: false,
			};
		}

		if (withserver) {
			let proof = [];

			await axios
				.get("https://proofs.avolox.com:2053/?user=akcb&address=" + address)
				.then((response) => {
					proof = response.data;
				})
				.catch((err) => {
					alert("Error: " + err);
				});

			if (proof.length > 0 && proof != "OK") {
				return {
					status: true,
					leaf: keccak256(address),
					proof: proof,
				};
			} else {
				return {
					status: false,
				};
			}
		}
		// NO SERVER

		try {
			const proof = generate_proof(address);

			if (proof.length === 0) {
				return {
					status: false,
				};
			}

			return {
				status: true,
				leaf: keccak256(address),
				proof: proof,
			};
		} catch (e) {
			console.log(e);
		}

		return {
			status: false,
		};
	}


	const notify = (msg) => toast.info(msg, { autoClose: 7000, toastId: "notify" });

	useEffect(() => {
		async function fetchData() {
			try{
				if (!isConnected) {
					setTimeout(()=>{
						showConnectModal("show");
					},500);

				}
			}
			catch {}

			startMinting();


			//addWalletListener();
		}
		fetchData();
	},[]);

	const startMinting = () => {
		if (document.getElementById("mintingStatus").innerHTML.indexOf("Fetching") >= 0) {
			setTimeout(refreshMintingStatus, 5000);
			//refreshInformations();
		} else {
			setTimeout(startMinting, 1000);
		}
	};

	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <Completionist />;
		} else {
			// Render a countdown
			const _minutes = minutes.toString().padStart(2, "0");
			const _seconds = seconds.toString().padStart(2, "0");
			if (days) {
				const _daysText = days > 1 ? "Days" : "Day";
				if (hours) {
					const _hours = hours.toString().padStart(2, "0");
					return (
						<span>
							{days} {_daysText} - {_hours}:{_minutes}:{_seconds}
						</span>
					);
				} else {
					return (
						<span>
							{days} {_daysText} - {_minutes}:{_seconds}
						</span>
					);
				}
			} else if (hours) {
				const _hours = hours.toString().padStart(2, "0");
				return (
					<span>
						{_hours}:{_minutes}:{_seconds}
					</span>
				);
			}

			return (
				<span>
					{_minutes}:{_seconds}
				</span>
			);
		}
	};

	const Completionist = () => {
		return mintingText;
	};

	const setRef = (countdown) => {
		if (countdown) {
			countdownapi = countdown.getApi();
		}
	};


	async function refreshMintingStatus() {
		try {

			if (theMintingStatus == true) {
				setMintingText("Minting...");
			} else {
				// setMintingText("Sale Ended");
			}
		} catch {
			console.log("Error");
		}

		setTimeout(refreshMintingStatus, 5000);
	}


	const connectWalletPressed = async () => {
		showConnectModal("hide");
		accessDeniedModal("hide");

		open();
	};

	function showConnectModal(val) {
		if (val == "show") {
			window.$("#connectWalletModal").modal("hide");
		}

		window.$("#connectModal").modal(val);
	}
	function accessDeniedModal(val) {
		showConnectModal("hide");
		window.$("#connectWalletModal").modal(val);
	}
	function showMintingModal(val) {
		window.$("#loadingModal").modal(val);
	}
	const onMintPressed = async () => {
		if (currentSupplyIndex == 0) {
			toast.error("Please select any of the 3 options to mint your character", {
				toastId: "insufficient-options-selected",
			});
			return;
		}



		if (address === undefined) {
			notify("Please connect the wallet first");
			showConnectModal("show");
			return;
		}

		if (parseInt(mintAmount) > parseInt(maxMintAmount)) {
			notify("You can only mint " + maxMintAmount + " NFTs at a time!");
			return;
		}

		if (document.getElementById("mintingStatus").innerHTML.indexOf("Minting...") >= 0) {
			var proof = [];

			if (onlyBeastListed) {
				var foundIt = await checkBeastlist();
				if (!foundIt.status) {
					toast.error("You're not in the beast list", {toastId: 'not-in-beastlist'});
					accessDeniedModal("show");
					return;
				} else {
					//toast.success("Beast list User Authorized!", {toastId: 'beastlist-authorized'});
					proof = foundIt.proof;
				}
			}

			const amountToSend = web3.utils.toWei(currentCost, "ether");

			setConfigInfo({mintType:currentSupplyIndex, value:amountToSend.toString(), mintGender: selectedGender, proof: proof});

			//notify(status);
		} else if (document.getElementById("mintingStatus").innerHTML.indexOf("Sold out") >= 0) {
			toast.error("We're sold out for the current round, please check back later!", { toastId: "sold-out" });
			return;
		} else {
			toast.error("Minting is not live yet! come back when the count down is over", { toastId: "minting-not-live" });
		}
	};

	useEffect(() => {
		write?.();
	}, [configInfo])

	function changeWallet(){
		disconnect();
	}


	function selectItem(item) {
		if (ingredients.filter((x) => x == item).length > 0) {
			ingredients = ingredients.filter((x) => x != item);
			setCurrentSupplyIndex(0);
			setCurrentSupplyName("SELECT 3 OPTIONS");
		} else {
			var check = window.$(".card-list.attributes").find("input[type='checkbox']:checked").length;
			if (check == 3) {
				toast.error("You can only select maximum of 3 options", {
					toastId: "max-options-selected",
				});
			} else {
				ingredients.push(item);
				if (ingredients.length == 3) {
					setOrder();
				}
			}
		}
	}
	function selectGender(item) {
		window.$(".card-list.gender li .card-checkbox").filter(":checkbox").prop("checked", false);
		if (ingredients.length == 3) {
			setGender(item);
		} else {
			toast.error("Please select the above options first", {
				toastId: "max-options-selected",
			});
			setTimeout(() => {
				window.$(".card-list.gender li .card-checkbox").filter(":checkbox").prop("checked", false);
			}, 200);
		}
	}

	function setOrder() {
		//find the order
		const myData = [].concat(ingredients).sort((a, b) => (a > b ? 1 : -1));

		const stringData = myData.reduce((result, item) => {
			return `${result}${item}`;
		}, "");

		var found = archeTypes.filter((x) => x.order == stringData)[0];

		setCurrentSupplyIndex(found.id);
		setCurrentSupplyName(found.parent);
		if (selectedGender == 2) window.$(".card-list.gender li .card-checkbox").filter(":checkbox").last().prop("checked", true);
	}

	function getSupply() {
		if (ingredients.length == 3) {
			if (selectedGender == 0) return currentSupplyMale[currentSupplyIndex];
			else if (selectedGender == 1) return currentSupplyFemale[currentSupplyIndex];
			else return Number(currentSupplyMale[currentSupplyIndex]) + Number(currentSupplyFemale[currentSupplyIndex]);
		} else {
			return 0;
		}
	}

	return (
		<div>

			<div className="header">

				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="container justify-content-between justify-content-center">
						<a className="navbar-brand" href="index.html">
							<img className="lazy" data-src="./assets/img/svg/logo.svg" alt="" />
						</a>
						<div onClick={connectWalletPressed} className="wallet-connect-nav-item">
							<span>{address !== undefined ? String(address).substring(0, 9) + "..." + String(address).substring(38) : <span>CONNECT WALLET</span>}</span>
							{address !== undefined && <img width="20" height="20" src="/assets/img/svg/MetaMask_Fox.png" alt="" />}
						</div>
					</div>
				</nav>
			</div>
			<div className="main-content">
				<div className="beast-section">
					<div className="container">
						<div className="row">
							<div className="col-md-7">
								<h1 className="main-title">
									Playtime <br /> is over
								</h1>
							</div>
							<div className="col-md-5">
								<div className="content mt-3">
									<p>
										YOU WILL SOON BE A HOLDER OF A KID CALLED BEAST. THERE ARE 20 BEASTHOODS, EACH 500 STRONG. SELECT 3 OPTIONS BELOW AND A RECEIPT WILL BE MINTED. THIS RECEIPT
										WILL TRANSFORM INTO YOUR BEAST ON LAUNCH.
									</p>
									<div className="price-flex-align-wrap">
										<div className="price">
											<span>Current Round: </span>
											<span>{currentRoundName} | </span>
											<span style={{ fontWeight: "bold" }} id="mintingStatus">
												<Countdown
													autoStart={false}
													//
													date={currentTime}
													renderer={renderer}
													ref={setRef}
												/>
											</span>
										</div>
										<div className="price">
											<span>Round Price</span>
											<img className="lazy" data-src="././assets/img/svg/Ethereum 1.svg" alt="" />
											<span>{currentCost} ETH</span>
										</div>
									</div>
								</div>
							</div>
                            <div className="col-md-7">
                                <div className="d-flex justify-content-between">
                                    <div className="pe-lg-5">
                                        <h5 className="dear-beasts-title text-uppercase">Dear "Beasts"</h5>
                                        <p>We are beyond excited to welcome you into the community of Beast Side. As we have said, we are incredibly excited to work together to maximize the incredible IP we have built for this project, and the beautiful community that is coalescing around it. Unlike many other Founders/Projects, we do not take IP lightly, and we do not underestimate the incredible opportunity IP holds for the NFT space, and our community. Neither should you. Accordingly, we are working diligently to make sure the grant of IP rights (copyright/trademark) are CLEAR, CONSPICUOUS, and UNDERSTANDABLE so that you know not only WHAT you can do with your IP, but HOW you can harness it, us, and the incredible community as well. The exciting and innovative IP terms will be delivered to the community in short order. Until then, no rights of any kind (other than the personal right to display as your PFP, or otherwise) are conferred by us to any holders, unless otherwise agreed to in writing.</p>

                                        <p>We Beast together, we Build together!!</p>
                                    </div>
                                </div>
                            </div>
						</div>
						<div className="content-wrapper">
							<div className="row">
								<div className="col-md-7">
									<div
										className="position-relative"
										style={{
											height: "100%",
											display: "flex",
											flexDirection: "column",
										}}
									>
										<div className="top-section">
											<div className="d-flex justify-content-between">
												<h5 className="secondary-title text-uppercase position-relative">what kind of beast are you?</h5>
												<div className="pe-lg-5">
													<a className="link-random" href="javascript:void(0)" onClick={randomize} id="random">
														<img width={17} height={12} src="./assets/img/svg/randomize.svg" alt="" />
														randomize
													</a>
												</div>
											</div>
										</div>
										<div style={{ flex: 1 }}>
											<ul className="card-list attributes pe-lg-5" role="group" aria-label="Basic checkbox toggle button group">
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btncheck1" value="1" autocomplete="off" />
													<label onClick={() => selectItem(1)} className="card" for="btncheck1" data-aos-once="true">
														<h4 className="card-title text-uppercase">honest</h4>
														<p>"Being honest may not get you a lot of friends but it’ll always get you the right ones." — John Lennon</p>
													</label>
												</li>
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btncheck2" value="2" autocomplete="off" />
													<label onClick={() => selectItem(2)} className="card" for="btncheck2" data-aos-once="true">
														<h4 className="card-title text-uppercase">sensitive</h4>
														<p>"To awaken human emotion is the highest level of art." — Isadora Duncan</p>
													</label>
												</li>
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btncheck3" value="3" autocomplete="off" />
													<label onClick={() => selectItem(3)} className="card" for="btncheck3" data-aos-once="true">
														<h4 className="card-title text-uppercase">loud</h4>
														<p>"Loud is a way of life" — Steve Adler</p>
													</label>
												</li>
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btncheck4" value="4" autocomplete="off" />
													<label onClick={() => selectItem(4)} className="card" for="btncheck4" data-aos-once="true">
														<h4 className="card-title text-uppercase">stoic</h4>
														<p>"Dont worry about a thing, every little thing is gonna be alright." — Bob Marley</p>
													</label>
												</li>
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btncheck5" value="5" autocomplete="off" />
													<label onClick={() => selectItem(5)} className="card" for="btncheck5" data-aos-once="true">
														<h4 className="card-title text-uppercase">aware</h4>
														<p>To be aware of a single shortcoming in oneself is more useful than to be aware of a thousand in someone else. — Dalai Lama XIV</p>
													</label>
												</li>
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btncheck6" value="6" autocomplete="off" />
													<label onClick={() => selectItem(6)} className="card" for="btncheck6" data-aos-once="true">
														<h4 className="card-title text-uppercase">ambitious</h4>
														<p>"A man’s worth is no greater than his ambitions." — Marcus Aurelius</p>
													</label>
												</li>
											</ul>
										</div>

										{/** Gender Selection */}

										<div className="top-section mt-3">
											<div className="d-flex justify-content-between">
												<h5 className="secondary-title text-uppercase position-relative">which gender are you?</h5>
											</div>
										</div>
										<div style={{ flex: 1 }}>
											<ul
												className="card-list pe-lg-5 gender"
												style={{ gridTemplateColumns: "1fr 1fr 1fr", gridTemplateRows: "1fr" }}
												role="group"
												aria-label="Basic checkbox toggle button group"
											>
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btnMale" autocomplete="off" />
													<label onClick={() => selectGender(0)} className="card" for="btnMale" data-aos-once="true">
														<h4 className="card-title text-uppercase m-1 text-center">Male</h4>
													</label>
												</li>
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btnFemale" autocomplete="off" />
													<label onClick={() => selectGender(1)} className="card" for="btnFemale" data-aos-once="true">
														<h4 className="card-title text-uppercase m-1 text-center">Female</h4>
													</label>
												</li>
												<li className="card-wrapper">
													<input type="checkbox" className="card-checkbox" id="btnRandom" autocomplete="off" />
													<label onClick={() => selectGender(2)} className="card" for="btnRandom" data-aos-once="true">
														<h4 className="card-title text-uppercase m-1 text-center">Random</h4>
													</label>
												</li>
											</ul>
										</div>

										<div className="pe-lg-5">
											<div className="progress position-relative">
												<div className="progress-info d-flex justify-content-between w-100 position-absolute">
													<p className="text-uppercase m-0 ms-4">{currentSupplyName}</p>
													<p className="text-uppercase m-0 me-4">{getSupply()} / 500 remaining</p>
												</div>
												<div
													className="progress-bar"
													role="progressbar"
													style={{
														width: (getSupply() / 500) * 100 + "%",
													}}
													aria-valuenow="0"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
										</div>
									</div>
								</div>
								<div className="receipt-wrapper">
									<div className="top-section">
										<h5 className="secondary-title text-uppercase">receipt</h5>
									</div>
									<div className="img-wrapper">
										<video autoPlay={true} loop={true} muted={true} playsInline={true} className="mint-img lazy img-fluid">
											<source src="./assets/video/prereveal.mp4" type="video/mp4" />
										</video>
										{/* <img className="mint-img lazy img-fluid" data-src="./assets/img/png/akcb-receipt-blank.png" alt="" /> */}

										<button onClick={onMintPressed} className="btn rounded-pill btn-mint text-uppercase position-absolute">
											mint
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="connectModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<button type="button" className="btn-close" data-bs-dismiss="modal" style={{ display: "none" }} aria-label="Close"></button>
						<div className="modal-body">
							<div className="connect_wallet_modal">
								<p>MINT ENGINE ACCESS</p>
								<p>
									TO SECURE A POSITION IN THE BEASTHOOD, YOUR WALLET MUST BE BEAST LISTED. EITHER YOU MUST BE GRANTED AN INVITATION OR HAVE SUCCESSFULLY AQUIRED A BEAST LIST POSITION
									THROUGH OUR COMMUNITY.
								</p>
								<p>IF YOUR WALLET IS NOT BEAST LISTED, THE ENGINE SHALL REMAIN LOCKED.</p>
							</div>
						</div>
						<div className="modal-footer">
							<button onClick={connectWalletPressed} className="btn btn-outline-dark rounded-pill connect-wallet">
								{" "}
								<span>{address !== undefined ? String(address).substring(0, 9) + "..." + String(address).substring(38) : <span>Connect Wallet</span>}</span>
							</button>
							<br />
							<a target="_blank" href="https://metamask.io/">
								Don’t have a wallet?
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="connectWalletModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<button type="button" style={{ display: "none" }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						<div className="modal-body">
							<div className="connect_wallet_modal">
								<p>ACCESS DENIED</p>
								<p>UNFORTUNATELY YOUR WALLET IS NOT ON THE BEAST LIST. YOU CAN EITHER APPLY FOR AN INVITATION or SEEK A BEAST LIST POSITION THROUGH OUR COMMUNITY.</p>

							</div>
						</div>
						<div className="modal-footer">
							<div className="d-md-flex gap-4">
								<a href="https://akidcalledbeast.com" className="btn btn-outline-dark rounded-pill">
									Back Home
								</a>
								<a href="http://discord.gg/akidcalledbeast" className="btn btn-outline-dark rounded-pill">
									Discord
								</a>

							</div>
							<button onClick={changeWallet} className="btn btn-outline-dark rounded-pill connect-wallet">
								{" "}
								<span>Change Wallet</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade loadingModal" id="loadingModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content" style={{ background: "#e2dfe3" }}>
						<div className="modal-body" style={{ marginBottom: "-7px" }}>
							<div className="title">Minting in Progress...</div>
							<video autoPlay={true} loop={true} muted={true} playsInline={true} className="img-fluid" style={{ borderRadius: "15px" }}>
								<source src="./assets/video/mint-in-progress.mp4" type="video/mp4" />
							</video>

							{/* <img className="img-fluid lazy" data-src="assets/img/gif/loading.gif" alt="" /> */}
						</div>
					</div>
				</div>
			</div>

			<ToastContainer position="bottom-right" autoClose={5000} transition={Slide} hideProgressBar />

		</div>
	);
};

export default Minter;
