import keccak256 from "keccak256";

const addresses = require('./beastlist.json').wallets;

export const generate_proof = (wallet_address) => {
    const MerkleTree = window.MerkleTree;
    const leaves = addresses.map(x => keccak256(x));
    const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
    const buf2hex = x => '0x' + x.toString('hex');

    window.vhash = buf2hex(tree.getRoot());

    const leaf = keccak256(wallet_address);
    return tree.getProof(leaf).map(x => buf2hex(x.data));
};
