export const AKCB_CONTRACT_ADDRESS = "0x77372a4cc66063575b05b44481F059BE356964A4";
// export const AKCB_CONTRACT_ADDRESS = "0x6931B2abc5952ccA7E0EB8B9DF460Dd28376a7CA"; // TESTNET
export const USE_INJECTED_PROVIDER = false;
export const REFRESH_RATE = 30000; // 30 secs
const INFURA = [
    '7f94af80db2f464786eb11a02afa3287',
    '8046b42ff55c4523b1a1b47e4fcff304',
    '3428502c6ab243ee9f8dd743a2343531'
];
const RPCS = {
	ankr: {
		goerli: "https://rpc.ankr.com/eth_goerli",
		rinkeby: "https://rpc.ankr.com/eth_rinkeby",
		mainnet: "https://rpc.ankr.com/eth",
	},
	infura: {
		goerli: `https://goerli.infura.io/v3/${INFURA[0]}`,
		rinkeby: `https://rinkeby.infura.io/v3/${INFURA[0]}`,
		mainnet: `https://mainnet.infura.io/v3/${INFURA[0]}`,
	},
    alchemy: {
        mainnet: "https://eth-mainnet.g.alchemy.com/v2/AL8BTFoe2wI0mtqiQi6_X2SQt5i8SlXQ",
    },
    chainstack: {
        mainnet: "https://nd-486-520-315.p2pify.com/ddb658dcd30fb03ec9a157c9aa8f3b7f"
    }
};

// export const WEB3_RPC_URL = process.env.VIP_BUILD ? RPCS["ankr"]["mainnet"] : RPCS["infura"]["mainnet"];
// export const WEB3_RPC_URL = RPCS["alchemy"]["mainnet"];
export const WEB3_RPC_URL = RPCS["ankr"]["mainnet"];
// export const WEB3_RPC_URL = RPCS["chainstack"]["mainnet"];
// export const WEB3_RPC_URL = RPCS["ankr"]["goerli"];
